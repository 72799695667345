import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
import BoardComponent from './BoardComponent'; // Replace with your actual component

ReactDOM.render(
  <Router>
    <Routes>
      <Route path="/boards/:boardId/:salt" element={<BoardComponent />} />
      <Route path="/" element={<App />} />
    </Routes>
  </Router>,
  document.getElementById('root')
);
