import React, { useState } from 'react';
import './Header.css';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

function Header() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRequestProClick = () => {
    window.open('https://linktoretro.com/pricing', '_blank');
    handleClose();
  };

  const handleTermsClick = () => {
    window.open('https://linktoretro.com/t-c', '_blank'); // Replace with actual URL
    handleClose();
  };

  return (
    <header className="header">
      <div className="header-content">
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleClick}
          sx={{ color: '#bb86fc' }}
        >
          <MenuIcon />
        </IconButton>
        <a href="/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
          <span className="header-title">linkto<strong>retro</strong></span>
        </a>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              padding: '10px 20px',
              fontWeight: 'bold',
              color: '#bb86fc',
              cursor: 'default',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            My Boards
          </Typography>
          <MenuItem onClick={handleRequestProClick}>
            <OpenInNewIcon sx={{ marginRight: '10px' }} />
            Get Pro Account
          </MenuItem>
          <Typography
            variant="subtitle1"
            sx={{
              padding: '10px 20px',
              fontWeight: 'bold',
              color: '#bb86fc',
              cursor: 'default',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            About
          </Typography>
          <MenuItem onClick={handleTermsClick}>
          <OpenInNewIcon sx={{ marginRight: '10px' }} />
            Terms & Conditions
          </MenuItem>
        </Menu>
      </div>
    </header>
  );
}

export default Header;
