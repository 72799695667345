import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import './BoardComponent.css';
import Header from './Header';
import { Helmet } from 'react-helmet';
import {
  IconButton,
  Tooltip,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'; // Importing a new icon
import InfoIcon from '@mui/icons-material/Info'; // Import InfoIcon
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


function BoardComponent() {
  const { boardId, salt } = useParams();
  const [boardData, setBoardData] = useState(null);
  const [error, setError] = useState('');
  const [votedCards, setVotedCards] = useState({});
  const [open, setOpen] = useState(false);
  const [cardType, setCardType] = useState('GREEN'); // Default to "I'm happy about"
  const [cardText, setCardText] = useState('');
  const [formError, setFormError] = useState('');
  const [loading, setLoading] = useState(false); // New loading state

  const [copyTooltip, setCopyTooltip] = useState('Copy link to clipboard');
  const handleCopyLink = () => {
    const url = window.location.href;
    navigator.clipboard.writeText(url).then(() => {
      setCopyTooltip('Link to retro copied to clipboard!');
      setTimeout(() => setCopyTooltip('Copy link to clipboard'), 3000); // Reset tooltip text after 3 seconds
    });
  };


  // Memoize fetchBoardData to prevent unnecessary re-creations
  const fetchBoardData = useCallback(async () => {
    try {
      const response = await fetch('https://parseapi.back4app.com/functions/getBoardCards', {
        method: 'POST',
        headers: {
          'X-Parse-Application-Id': '3p0gg6JiJlhIElzLlhah14yVU5A3ftfsfMrervFd',
          'X-Parse-REST-API-Key': 'uABn0ZMKt192Tvbx5ywNQpBYCPydjc8yo98jNv47',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ salt, boardId }),
      });

      const result = await response.json();
      if (response.ok) {
        setBoardData(result.result);
      } else {
        setError('Failed to fetch board data.');
      }
    } catch (err) {
      console.error('Error fetching board data:', err);
      setError('An error occurred. Please try again.');
    }
  }, [boardId, salt]);

  useEffect(() => {
    fetchBoardData();
  }, [fetchBoardData]);

const handleUpvote = async (cardId) => {
  try {
    // Optimistically update local state to highlight the upvote button
    setVotedCards((prev) => ({
      ...prev,
      [cardId]: true,  // Mark the card as upvoted locally
    }));

    // Send a POST request to increase the upvote
    const response = await fetch('https://parseapi.back4app.com/functions/increaseUpvote', {
      method: 'POST',
      headers: {
        'X-Parse-Application-Id': '3p0gg6JiJlhIElzLlhah14yVU5A3ftfsfMrervFd',
        'X-Parse-REST-API-Key': 'uABn0ZMKt192Tvbx5ywNQpBYCPydjc8yo98jNv47',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ cardId }),
    });

    if (response.ok) {
      // Refetch the board data after a successful upvote
      await fetchBoardData();
    } else {
      console.error('Failed to upvote the card');
      // If the API call fails, revert the local state
      setVotedCards((prev) => ({
        ...prev,
        [cardId]: false,
      }));
    }
  } catch (err) {
    console.error('Error upvoting the card:', err);
    // Revert the local state on error
    setVotedCards((prev) => ({
      ...prev,
      [cardId]: false,
    }));
  }
};
  

const renderCards = (type) => {
    const typeToClass = {
      GREEN: 'card-green',
      RED: 'card-red',
      PURPLE: 'card-purple'
    };

    // Sort cards based on the specified criteria: 
    // 1. upvotes descending
    // 2. createdAt descending if upvotes are the same
    const sortedCards = boardData.cards
      .filter((card) => card.type === type)
      .sort((a, b) => {
        if (b.upvotes !== a.upvotes) {
          return b.upvotes - a.upvotes; // Sort by upvotes (descending)
        } else {
          return new Date(a.createdAt.iso) - new Date(b.createdAt.iso); // Sort by createdAt (ascending)
        }
      });

    return sortedCards.map((card) => (
        <div key={card.id} className={`card ${typeToClass[type]}`}>
          <p>{card.text}</p>
          <span className="upvotes">
            <ThumbUpAltIcon
                className={`upvote-icon ${votedCards[card.id] ? 'voted' : ''}`}
                onClick={() => handleUpvote(card.id)}
                style={{
                fontSize: '1rem',
                color: votedCards[card.id] ? '#bb86fc' : 'inherit',
                cursor: 'pointer',
                }}
            />
            <span style={{ fontWeight: votedCards[card.id] ? 'bold' : 'normal' }}>
                {card.upvotes}
            </span>
        </span>
        </div>
    ));
};


const renderColumnHeader = (title, type) => (
    <div className="column-header">
      <h2>
        {title}
        {type === 'PURPLE' && (
          <Tooltip title="Make sure to review action items in the beginning of next retro." arrow>
            <InfoIcon className="info-icon" />
          </Tooltip>
        )}
      </h2>
      <Tooltip title="Create card" arrow>
        <IconButton size="small" className="add-card-button" onClick={() => handleClickOpen(type)}>
          <AddIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </div>
  );
  

  const handleClickOpen = (type = 'GREEN') => {
    setCardType(type); // Set the card type based on which column's plus icon was clicked or default to 'GREEN'
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCardType('GREEN');
    setCardText('');
    setFormError('');
  };

  const handleCreateCard = async () => {
    if (cardText.length < 1 || cardText.length > 400) {
      setFormError('Text must be between 1 and 400 characters.');
      return;
    }

    setLoading(true); // Set loading state to true when request starts
    try {
      const response = await fetch('https://parseapi.back4app.com/functions/createRetroCard', {
        method: 'POST',
        headers: {
          'X-Parse-Application-Id': '3p0gg6JiJlhIElzLlhah14yVU5A3ftfsfMrervFd',
          'X-Parse-REST-API-Key': 'uABn0ZMKt192Tvbx5ywNQpBYCPydjc8yo98jNv47',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          text: cardText,
          type: cardType,
          boardId: boardId,
          salt: salt,
        }),
      });

      if (response.ok) {
        console.log('Card created successfully!');
        await fetchBoardData();
        handleClose(); // Close the modal on success 
      } else {
        setFormError('Failed to create the card. Please try again.');
      }
    } catch (error) {
      console.error('Error creating card:', error);
      setFormError('An error occurred. Please try again.');
    } finally {
      setLoading(false); // Reset loading state when request completes
    }
  };

  const renderEmptyBoardMessage = () => (
    <div className="empty-board-message">
      <p>Be the first one to add a card!</p>
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => handleClickOpen('GREEN')}
        className="create-card-button"
      >
        Create Card
      </Button>
    </div>
  );

  // Define a helper function to get the background color based on the card type
  const getBackgroundColor = (type) => {
    switch (type) {
      case 'GREEN':
        return '#d4edda'; // light green
      case 'RED':
        return '#f8d7da'; // light red
      case 'PURPLE':
        return '#e6d4ed'; // light purple
      default:
        return 'transparent';
    }
  };

  return (
    <div className="board-container">
      <Helmet>
        <title>
          {boardData
            ? `${boardData.name} | linktoretro.com`
            : 'Retro Board | linktoretro.com'}
        </title>
      </Helmet>
      <Header />
      <main className="board-content">
        {error && <p className="error-message">{error}</p>}
        {boardData ? (
          <div className="board-wrapper">
          <div className="board-header">
                <h1 className="board-title">
                  {boardData.name}
                  <Tooltip title="Public board. Anyone with the link can view the board, add cards, and upvote cards." arrow>
                    <InfoIcon fontSize="small" className="board-info-icon" />
                  </Tooltip>
                  <Tooltip title={copyTooltip} arrow>
                    <IconButton onClick={handleCopyLink} size="small">
                      <ContentCopyIcon fontSize="small" className="board-info-icon"/>
                    </IconButton>
                  </Tooltip>
                </h1>
                <span className="board-ttl">
                    {boardData.ttl <= 1 
                    ? "This board will be depublished within 1 day" 
                    : `This board will be depublished in ${boardData.ttl} days`}
                </span>
            </div>

            <div className="columns-container">
              <div className="column">
                {renderColumnHeader("I'm happy about", 'GREEN')}
                <div className="cards-container">{renderCards('GREEN')}</div>
              </div>
              <div className="column">
                {renderColumnHeader("Things to improve", 'RED')}
                <div className="cards-container">{renderCards('RED')}</div>
              </div>
              <div className="column">
                {renderColumnHeader("Action items", 'PURPLE')}
                <div className="cards-container">{renderCards('PURPLE')}</div>
              </div>
            </div>
            {boardData.cards.length === 0 && renderEmptyBoardMessage()}
          </div>
        ) : (
          !error && (
            <div className="loading-container">
              <CircularProgress className="loading-indicator" />
            </div>
          )
        )}
      </main>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create card</DialogTitle>
        <DialogContent>
          <TextField
            select
            label="Type"
            value={cardType}
            onChange={(e) => setCardType(e.target.value)}
            fullWidth
            margin="dense"
            variant="outlined"
            sx={{
              marginBottom: '20px',
              backgroundColor: getBackgroundColor(cardType),  // Set background color based on card type
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'black',
                },
                '&:hover fieldset': {
                  borderColor: 'black',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'black',
                },
              },
              '& .MuiInputLabel-root': {
                color: 'grey',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: 'grey',
              },
            }}
          >
            <MenuItem value="GREEN">I'm happy about</MenuItem>
            <MenuItem value="RED">Things to improve</MenuItem>
            <MenuItem value="PURPLE">Action items</MenuItem>
          </TextField>

          <TextField
            label="Your Text"
            multiline
            rows={4}
            value={cardText}
            onChange={(e) => setCardText(e.target.value)}
            fullWidth
            variant="outlined"
            autoFocus  // Auto focus on the Text field
            error={!!formError}
            helperText={formError}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'black',
                },
                '&:hover fieldset': {
                  borderColor: 'black',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'black',
                },
              },
              '& .MuiInputLabel-root': {
                color: 'grey',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: 'grey',
              },
            }}
          />

          {/* Character Counter */}
          <Typography
            variant="body2"
            color="textSecondary"
            align="right"
            sx={{ marginTop: '8px' }}
          >
            {cardText.length} / 400
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} sx={{ color: '#bb86fc' }}>Cancel</Button>
          <Button
            onClick={handleCreateCard}
            disabled={!cardText || loading}
            variant="contained"
            sx={{
              backgroundColor: '#bb86fc',
              '&:hover': {
                backgroundColor: '#9c4dcc',
              },
              color: '#fff',
              fontWeight: 'bold',
            }}
          >
            {loading ? <CircularProgress size={24} sx={{ color: 'black' }} /> : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default BoardComponent;
