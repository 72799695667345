import React, { useState } from 'react';
import './App.css';
import Header from './Header';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Box from '@mui/material/Box';

function App() {
  const [open, setOpen] = useState(false);
  const [boardName, setBoardName] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setBoardName('');
    setError('');
  };

  const handleCreate = async () => {
    if (boardName.length < 1 || boardName.length > 60) {
      setError('Board name must be between 1 and 60 characters.');
      return;
    }

    setLoading(true);
    try {
      const response = await fetch('https://parseapi.back4app.com/functions/createRetroBoard', {
        method: 'POST',
        headers: {
          'X-Parse-Application-Id': '3p0gg6JiJlhIElzLlhah14yVU5A3ftfsfMrervFd',
          'X-Parse-REST-API-Key': 'uABn0ZMKt192Tvbx5ywNQpBYCPydjc8yo98jNv47',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: boardName }),
      });

      const result = await response.json();
      if (response.ok && result?.result?.boardId && result?.result?.salt) {
        const { boardId, salt } = result.result;
        navigate(`/boards/${boardId}/${salt}`);
      } else {
        setError('Failed to create board. Please try again.');
      }
    } catch (error) {
      console.error('Error creating board:', error);
      setError('An error occurred. Please try again.');
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  const handleGetPro = () => {
    window.open('https://linktoretro.com/pricing', '_blank');
  };

  return (
    <div>
      <Helmet>
        <title>{`Create Retro Board | linktoretro.com`}</title>
      </Helmet>
      <Header />
      <main className="main-content">
        <div className="centered-container">
          <button className="create-board-button" onClick={handleClickOpen}>
            <span className="plus-icon">+</span>
            Create New Board
          </button>
        </div>
      </main>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create New Retro Board</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Give It a Nice Name"
            type="text"
            fullWidth
            variant="outlined"
            value={boardName}
            onChange={(e) => {
              setBoardName(e.target.value);
              setError('');
            }}
            error={!!error}
            helperText={error}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'black',
                },
                '&:hover fieldset': {
                  borderColor: 'black',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'black',
                },
              },
              '& .MuiInputLabel-root': {
                color: 'black',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: 'black',
              },
            }}
          />
          <Box sx={{ mt: 2 }}>
            <DialogContentText>
              The board will be automatically removed after 28 days. Save the link to your retro board.
            </DialogContentText>
            <Button
              onClick={handleGetPro}
              variant="outlined"
              sx={{
                mt: 1,
                borderColor: '#bb86fc',
                color: '#bb86fc',
                '&:hover': {
                  borderColor: '#9c4dcc',
                  backgroundColor: 'rgba(187, 134, 252, 0.04)',
                },
                textTransform: 'none',
                fontWeight: 'bold',
              }}
            >
              Get Pro Account
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{ color: '#bb86fc' }}>Cancel</Button>
          <Button
            onClick={handleCreate}
            disabled={loading || !boardName}
            variant="contained"
            sx={{
              backgroundColor: '#bb86fc',
              '&:hover': {
                backgroundColor: '#9c4dcc',
              },
              color: '#fff',
              fontWeight: 'bold',
            }}
          >
            {loading ? <CircularProgress size={24} sx={{ color: 'black' }} /> : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default App;